<script>
import {CollapseTransition} from "@ivanv/vue-collapse-transition/src";

export default {
  components: {
    CollapseTransition
  },
  data() {
    return {
      showCooperationModels: false,
      showDevelopmentProcess: false
    }
  },
  methods: {
    showCooperation() {
      this.showCooperationModels = true
    },
    hideCooperation() {
      this.showCooperationModels = false
    },
    showDevelopment() {
      this.showDevelopmentProcess = true
    },
    hideDevelopment() {
      this.showDevelopmentProcess = false
    }
  }
}
</script>

<template>
  <section id="services" class="max-w-[1110px] xl:max-w-[1730px] xl:px-10  mx-auto py-[60px] px-[16px] md:px-5 md:py-[80px]">
    <h2 class="h2-heading uppercase mb-[32px] md:mb-[60px]">Services</h2>

    <div class="block flex-row-reverse md:flex gap-[120px] justify-between service-block">
      <div class="text_block xl:w-1/2 mb-[40px] md:mb-0">
        <h3 class="h3-heading uppercase">App Development</h3>

        <div class="mb-[20px]">
          <p class="body-text-bold text-justify">
            Our team of experts specializes in developing Connected TV applications for a wide range of platforms, including Roku, Amazon, Hisense, LG, and many others.
          </p>
          <p class="body-text-regular text-justify">
            In a short period, we can make your content available on all major CTV platforms worldwide, enabling your channel to reach a new audience and increase revenue. We'll handle everything from start to finish, saving you time and effort.
          </p>
        </div>

        <button class="h4-heading uppercase flex items-center gap-[16px]">
          <span>Our Development Process</span>
          <transition name="fade">
            <img @click="showDevelopment" v-show="!showDevelopmentProcess" src="@/assets/arrow_long.svg" alt="Show service arrow">
          </transition>
        </button>

        <collapse-transition>
          <div v-show="showDevelopmentProcess">
            <div class="my-[24px]">
              <div class="mb-[16px]">
                <h6 class="h7-heading red-text mb-[8px]">Platform Compatibility:</h6>
                <p class="body-text-regular text-justify">Our team ensures your application is optimized for each Connected TV platform, guaranteeing a smooth user experience.</p>
              </div>
              <div class="mb-[16px]">
                <h6 class="h7-heading red-text mb-[8px]">Design Customization:</h6>
                <p class="body-text-regular text-justify">Tailor the user interface to match the design guidelines of each platform, creating a visually appealing and user-friendly app.</p>
              </div>
              <div class="mb-[24px]">
                <h6 class="h7-heading red-text mb-[8px]">Quality Assurance:</h6>
                <p class="body-text-regular text-justify">Rigorous testing is conducted on various devices to eliminate bugs and ensure a flawless performance on each platform.</p>
              </div>
            </div>

            <div class="text-right">
              <button @click="hideDevelopment"><img src="@/assets/arrow_long_red.svg" alt="hide text"></button>
            </div>
          </div>
        </collapse-transition>
      </div>
      <div class="image_block">
<!--        <img src="/images/services/Illustration_1.png" alt="App Development">-->
        <img src="/images/services/1.png" alt="App Development">
      </div>
    </div>

    <div class="block md:flex gap-[120px] justify-between service-block my-[100px] md:my-[160px]">
      <div class="text_block xl:w-1/2 mb-[40px] md:mb-0">
        <h3 class="h3-heading uppercase">Monetization</h3>

        <div class="mb-[20px]">
          <p class="body-text-bold text-justify">
            The growing CTV advertising market opens new opportunities for content owners but requires a reliable ad delivery system at an optimal cost across all sales channels.
          </p>
          <p class="body-text-regular text-justify">
            Our solutions ensure efficient utilization of ad streaming capabilities, including ad break optimization and real-time audience interaction. With Monview content owners can maximize the fill rate and value of their ad inventory while enhancing operational efficiency and user convenience.
          </p>
        </div>

        <button class="h4-heading uppercase flex items-center gap-[16px]">
          <span>COOPERATION MODELS</span>
          <transition name="fade">
            <img @click="showCooperation" v-show="!showCooperationModels" src="@/assets/arrow_long.svg" alt="Show service arrow">
          </transition>
        </button>

        <collapse-transition>
          <div v-show="showCooperationModels">
            <div class="my-[24px]">
              <p class="body-text-bold text-justify mb-[16px]">Generate revenue from your live streams and on-demand videos with ads (AVOD), subscriptions (SVOD) or pay per view (TVOD).</p>
              <div class="mb-[16px]">
                <h6 class="h7-heading red-text mb-[8px]">AVOD</h6>
                <p class="body-text-regular text-justify">Advertising-supported video-on-demand is a spon-sorship model for video advertising, where the cost of ad placement typically depends on the size of the audience. AVOD is a classic monetization format where brands pay you for showing video ads to your audience.</p>
              </div>
              <div class="mb-[16px]">
                <h6 class="h7-heading red-text mb-[8px]">SVOD</h6>
                <p class="body-text-regular text-justify">Subscription-based video-on-demand is a utilized monetization method where users pay a recurring fee for access to video content.</p>
              </div>
              <div class="mb-[24px]">
                <h6 class="h7-heading red-text mb-[8px]">TVOD</h6>
                <p class="body-text-regular text-justify">Transactional video-on-demand, or "pay-per-view streaming," is another monetization method requiring viewers to pay in advance for each viewed video or requested digital download.</p>
              </div>
            </div>

            <div class="text-right">
              <button @click="hideCooperation"><img src="@/assets/arrow_long_red.svg" alt="hide text"></button>
            </div>
          </div>
        </collapse-transition>
      </div>
      <div class="image_block">
<!--        <img src="/images/services/Illustration_2.png" alt="Monetization">-->
        <img src="/images/services/2.png" alt="Monetization">
      </div>
    </div>

    <div class="block flex-row-reverse md:flex gap-[120px] justify-between service-block">
      <div class="text_block xl:w-1/2 mb-[40px] md:mb-0">
        <h3 class="h3-heading uppercase">Advertising</h3>
        <div>
          <p class="body-text-bold text-justify">
            Monview offers a wide range of CTV channels with ad-supported streaming (FAST), providing unique opportunities for advertisers to directly engage with high-quality CTV content. By featuring a variety of programs covering finance and investments, cryptocurrency news, trading, election updates and predictions, the latest news in the automotive industry and innovations.
          </p>
          <p class="body-text-regular text-justify">
            We enable advertisers to connect more closely with their audience by offering access to exclusive CTV content. Reliable data and unique innovations in advertising blocks help maximize the return on investment in CTV advertising.
          </p>
        </div>
      </div>
      <div class="image_block">
<!--        <img src="/images/services/Illustration_3.png" alt="Advertising">-->
        <img src="/images/services/3.png" alt="Advertising">
      </div>
    </div>
  </section>
</template>

<style scoped>
  h3 {
    margin-bottom: 20px;
  }

  .text_block div p:first-child {
    margin-bottom: 16px;
  }

  .text_block {
    max-width: 448px;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

@media (min-width: 1440px) {
  .text_block {
    max-width: 100%;
  }
}
</style>