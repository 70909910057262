<script setup>

</script>

<template>
  <section id="texts" class="max-w-[1110px] xl:max-w-[1730px] xl:px-10 mx-auto pt-[24px] md:pt-[40px] pb-[40px] md:pb-[80px] px-[16px] md:px-5">
    <div class="hero_texts block md:flex gap-[170px] justify-between">
      <div class="xl:max-w-[624px]">
        <h6 class="h6-heading text-white pb-[20px] md:pb-0">
          Monview is a video monetization platform for independent creators and enterprises that allows for the rapid creation of FAST channels for monetization and direct interaction with the audience.
        </h6>
      </div>
      <div class="xl:max-w-[570px]">
        <h4 class="h4-heading text-white uppercase md:text-right">YOUR ULTIMATE VIDEO MONETIZATION PARTNER!</h4>
        <p class="red-text body-text-regular md:text-right">We offer all the necessary tools for streaming and generating a stable income from videos, including built-in analytics and marketing tools that streamline operations in the video sphere.</p>
      </div>
    </div>
  </section>
</template>

<style scoped>
.hero_texts {

}
@media (min-width: 1440px) {

}
</style>