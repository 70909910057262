<script>
import MenuItem from "@/components/menu-item.vue";

export default {
  components: {
    MenuItem
  },
  data() {
    return {
      menuItems: [
        {title: "Home", link: "#home"},
        {title: "Services", link: "#services"},
        {title: "Products", link: "#products"},
        {title: "Contacts", link: "#contacts"},
      ],
      scrollPosition: null,
      showMobileMenu: false,
      minScroll: 1160,
      minMobileScroll: 700,
      maxScroll: 4080,
      maxMobileScroll: 3900,
    }
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY
    },
    displayMobileMenu() {
      this.showMobileMenu = true
    },
    hideMobileMenu() {
      this.showMobileMenu = false
    },
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  }
}
</script>

<template>
  <div id="navigation-bar" class="max-w-[1110px] xl:max-w-[1650px] mx-auto  px-[16px] md:px-0">
    <div class="hidden md:flex items-center justify-between navigation-content" :class="(scrollPosition < minScroll || scrollPosition > maxScroll) ? 'dark_theme' : ''">
      <div>
        <img src="@/assets/logo.svg" alt="MonView" v-if="scrollPosition >= minScroll && scrollPosition <= maxScroll">
        <img src="@/assets/Monview_white_logo.svg" alt="MonView" v-if="scrollPosition < minScroll || scrollPosition > maxScroll">
      </div>
      <div class="flex gap-[16px]">
        <menu-item v-for="(menuItem, index) in menuItems" :menu-item="menuItem" v-bind:key="index" />
      </div>
      <div>
        <a href="#contacts" class="product-button-font">
          <button class="uppercase flex gap-[16px] items-center get-in-touch">
            <span>Get in touch </span>
            <svg v-if="scrollPosition < minScroll || scrollPosition > maxScroll" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.52825 18.027L16.1452 9.41011L16.2464 17.0405C16.2545 17.6503 16.7615 18.1573 17.3713 18.1654C17.5161 18.1675 17.6591 18.141 17.7922 18.0874C17.9253 18.0338 18.0458 17.9542 18.1468 17.8532C18.2478 17.7522 18.3274 17.6317 18.381 17.4986C18.4345 17.3655 18.4611 17.2225 18.459 17.0777L18.3223 6.77351C18.3205 6.62871 18.2902 6.48492 18.2331 6.35037C18.176 6.21583 18.0932 6.09317 17.9894 5.98942C17.8857 5.88567 17.763 5.80287 17.6285 5.74575C17.4939 5.68863 17.3501 5.65831 17.2053 5.65654L6.90117 5.51986C6.75641 5.51794 6.61344 5.54455 6.48043 5.59818C6.34743 5.6518 6.22698 5.73139 6.12598 5.83239C6.02497 5.9334 5.94539 6.05384 5.89176 6.18685C5.83814 6.31985 5.81153 6.46282 5.81345 6.60758C5.81537 6.75234 5.84578 6.89607 5.90295 7.03054C5.96013 7.16502 6.04294 7.28762 6.14666 7.39134C6.25038 7.49506 6.37297 7.57787 6.50745 7.63504C6.64193 7.69221 6.78565 7.72263 6.93041 7.72455L14.5608 7.82576L5.94391 16.4427C5.51962 16.867 5.52895 17.5706 5.96465 18.0063C6.40034 18.442 7.10397 18.4513 7.52825 18.027Z" fill="#EEEEEE"/>
            </svg>
            <svg v-if="scrollPosition >= minScroll && scrollPosition <= maxScroll" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.52825 18.027L16.1452 9.41011L16.2464 17.0405C16.2545 17.6503 16.7615 18.1573 17.3713 18.1654C17.5161 18.1675 17.6591 18.141 17.7922 18.0874C17.9253 18.0338 18.0458 17.9542 18.1468 17.8532C18.2478 17.7522 18.3274 17.6317 18.381 17.4986C18.4345 17.3655 18.4611 17.2225 18.459 17.0777L18.3223 6.77351C18.3205 6.62871 18.2902 6.48492 18.2331 6.35037C18.176 6.21583 18.0932 6.09317 17.9894 5.98942C17.8857 5.88567 17.763 5.80287 17.6285 5.74575C17.4939 5.68863 17.3501 5.65831 17.2053 5.65654L6.90117 5.51986C6.75641 5.51794 6.61344 5.54455 6.48043 5.59818C6.34743 5.6518 6.22698 5.73139 6.12598 5.83239C6.02497 5.9334 5.94539 6.05384 5.89176 6.18685C5.83814 6.31985 5.81153 6.46282 5.81345 6.60758C5.81537 6.75234 5.84578 6.89607 5.90295 7.03054C5.96013 7.16502 6.04294 7.28762 6.14666 7.39134C6.25038 7.49506 6.37297 7.57787 6.50745 7.63504C6.64193 7.69221 6.78565 7.72263 6.93041 7.72455L14.5608 7.82576L5.94391 16.4427C5.51962 16.867 5.52895 17.5706 5.96465 18.0063C6.40034 18.442 7.10397 18.4513 7.52825 18.027Z" fill="#060606"/>
            </svg>
          </button>
        </a>
      </div>
    </div>
    <div class="flex items-center justify-between py-[12px] px-[8px] md:hidden navigation-content-mobile">
      <svg :class="(scrollPosition >= minMobileScroll && scrollPosition <= maxMobileScroll) ? 'dark_logo' : ''" width="104" height="20" viewBox="0 0 104 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0.212402H2.85457L7.48739 12.2974V17.2111H6.73865L2.85457 6.3213V19.7874H0V0.212402ZM13.0795 0.212402H15.9341V19.7874H13.0795V6.3213L9.21885 17.2111H8.44671V12.3505L13.0795 0.212402Z" fill="white"/>
        <path d="M17.7273 9.98672C17.7273 8.4108 17.8911 7.02081 18.2187 5.81673C18.5618 4.59495 19.0298 3.56795 19.6225 2.73572C20.2309 1.88579 20.9406 1.23949 21.7518 0.796813C22.5785 0.336432 23.4754 0.0708278 24.4425 0V2.65604C23.9122 2.72687 23.413 2.91279 22.9451 3.21381C22.4771 3.51483 22.0715 3.9575 21.7284 4.54183C21.3852 5.12616 21.1122 5.86985 20.9094 6.77291C20.7067 7.67596 20.5975 8.74723 20.5819 9.98672C20.5975 11.2439 20.7067 12.324 20.9094 13.2271C21.1122 14.1124 21.3852 14.8561 21.7284 15.4582C22.0871 16.0602 22.5005 16.5206 22.9685 16.8393C23.4364 17.1403 23.9278 17.3174 24.4425 17.3705V20C23.491 19.9292 22.6019 19.6724 21.7752 19.2297C20.9484 18.7694 20.2309 18.1231 19.6225 17.2908C19.0298 16.4409 18.5618 15.405 18.2187 14.1833C17.8911 12.9615 17.7273 11.5626 17.7273 9.98672ZM25.3785 0C26.33 0.0531209 27.2113 0.309872 28.0225 0.770252C28.8492 1.23063 29.5589 1.88579 30.1517 2.73572C30.76 3.56795 31.2358 4.59495 31.579 5.81673C31.9221 7.02081 32.0937 8.4108 32.0937 9.98672C32.0937 11.5626 31.9221 12.9615 31.579 14.1833C31.2358 15.405 30.76 16.4409 30.1517 17.2908C29.5589 18.1231 28.857 18.7694 28.0459 19.2297C27.2347 19.6724 26.3456 19.9292 25.3785 20V17.3705C25.9088 17.2997 26.408 17.1138 26.8759 16.8127C27.3439 16.494 27.7495 16.0425 28.0926 15.4582C28.4358 14.8561 28.7088 14.1124 28.9116 13.2271C29.1144 12.324 29.2236 11.2439 29.2392 9.98672C29.2236 8.72953 29.1144 7.65826 28.9116 6.77291C28.7088 5.86985 28.428 5.12616 28.0693 4.54183C27.7261 3.9398 27.3205 3.48827 26.8525 3.18725C26.3846 2.86853 25.8932 2.6826 25.3785 2.62948V0Z" fill="white"/>
        <path d="M43.5033 0.212402H46.3579V19.7874H43.5033L37.7006 8.20709V2.09819L43.5033 13.7051V0.212402ZM33.8867 19.7874V0.212402H36.7413V19.7874H33.8867Z" fill="white"/>
        <path d="M48.1511 0.15918H51.3567L54.9834 15.5111V19.8405H53.5093L48.1511 0.15918ZM59.5928 0.15918H62.7749L57.1594 19.8405H55.9427V14.6612L59.5928 0.15918Z" fill="white"/>
        <path d="M67.4224 19.7874H64.5679V0.212402H67.4224V19.7874Z" fill="white"/>
        <path d="M69.2156 0.212402H72.0701V19.7874H69.2156V0.212402ZM79.2768 10.9694H73.0295V8.79142H79.2768V10.9694ZM73.0295 17.3704H80.0021V19.7874H73.0295V17.3704ZM80.0021 0.212402V2.6294H73.0295V0.212402H80.0021Z" fill="white"/>
        <path d="M88.0191 17.0516L91.2948 0.15918H92.3945V7.25081L89.8441 19.8405H86.1706L81.7952 0.15918H84.9773L88.0191 17.0516ZM100.818 0.15918H104L99.3437 19.8405H95.6936L93.3538 7.75546V0.15918H94.4769L97.5187 17.0516L100.818 0.15918Z" fill="white"/>
      </svg>
      <div>
          <svg @click="displayMobileMenu" v-if="!showMobileMenu" class="hover:cursor-pointer" :class="(scrollPosition >= minMobileScroll && scrollPosition <= maxMobileScroll) ? 'dark_burger' : ''" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 6.00098H21M3 12.001H21M3 18.001H21" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <img @click="hideMobileMenu" v-if="showMobileMenu" class="hover:cursor-pointer" src="@/assets/clouse_small.svg" alt="Close menu">
      </div>
    </div>

    <transition name="fade">
      <div class="mobile-menu flex flex-col justify-between" v-show="showMobileMenu">
        <div class="menu-items flex flex-col gap-[24px] mt-[30vh]">
          <div v-for="(item, index) in menuItems" :key="index" class="text-center">
            <a :href="item.link" @click="hideMobileMenu" class="text-white h3-heading uppercase">{{ item.title }}</a>
          </div>
        </div>
        <div class="menu-footer">
          <div class="text-center">
            <div class="opacity-50 mb-[24px]">
              <a href="https://www.linkedin.com/company/monviewctv/about" target="_blank">
                <img src="@/assets/LinkedIn.svg" alt="MonView LinkedIn" class="mx-auto">
              </a>
            </div>
            <div class="flex items-center justify-center gap-[40px] text-white opacity-50 mb-[16px]">
              <router-link to="/privacy-policy" target="_blank" class="menu-font">Privacy Policy</router-link>
              <router-link to="/terms-conditions" target="_blank"  class="menu-font">Terms & Conditions</router-link>
            </div>
            <div class="red-text mb-[40px]">
              <p class="menu-font">© 2024 by Monview. All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped>
#navigation-bar {
  box-shadow: 0px 6px 12px 0px #00000024;
  border-radius: 20px;
  border: 1px solid #676767;
}

@media (max-width: 768px) {
  #navigation-bar {
    border-radius: unset;
    border: unset;
    box-shadow: unset;
  }
}

.navigation-content {
  background-color: rgba(0,0,0, 0.07);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 20px;
  padding: 16px 24px 16px 24px;
}

.navigation-content-mobile {
  background-color: rgba(0,0,0, 0.07);
  backdrop-filter: blur(5px);
  border-radius: 12px;
  border: 1px solid #676767;
  -webkit-backdrop-filter: blur(5px);
  box-shadow: 0px 6px 12px 0px #00000024;
}

.dark_theme {
  color: #ffffff;
}

.dark_burger path {
  stroke: #060606;
}

.dark_logo path{
  fill: #060606;
}

.get-in-touch:hover {
  color: #EB3756;
}

.get-in-touch:hover svg path {
  fill: #EB3756;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.mobile-menu {
  background-color: #060606;
  background-image: url("@/assets/color_gradient_mobile_bg.png");
  background-repeat: no-repeat;
  background-position: -240px center;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

.menu-items {
  //background-image: url("@/assets/color_gradient_bg.png");
}
</style>