import { createApp } from 'vue'
import App from './App.vue'
import './css/index.css'

import { createWebHistory, createRouter } from 'vue-router'

import HomeView from './pages/home-landing.vue'
import PrivacyPolicyView from './pages/privacy-policy.vue'
import TermsConditionsView from './pages/terms-conditions.vue'

const routes = [
    { path: '/', component: HomeView },
    { path: '/privacy-policy', component: PrivacyPolicyView },
    { path: '/terms-conditions', component: TermsConditionsView },
]

if (history.scrollRestoration) {
    history.scrollRestoration = "manual";
}

const router = createRouter({
    history: createWebHistory(),
    routes,
})

createApp(App).use(router).mount('#app')
