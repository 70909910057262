<script>
import axios from "axios";

export default {
  data() {
    return {
      name: null,
      email: null,
      questions: null,
      selector: []
    }
  },
  methods: {
    toggleSelect(value) {
      if (this.selector.includes(value)) {
        this.selector.splice(this.selector.indexOf(value), 1);
      } else {
        this.selector.push(value);
      }

      console.log(this.selector)
    },
    sendForm() {
      axios.post('/mail.php', {
        name: this.name,
        email: this.email,
        questions: this.questions,
        selector: this.selector
      }).then(() => {
        this.name = null
        this.email = null
        this.questions = null
        this.selector = []
      });
    }
  }
}
</script>

<template>
  <section id="contacts" class="max-w-[1110px] xl:max-w-[1730px] xl:px-10 mx-auto py-[60px] md:py-[80px] px-[16px] lg:px-5">
    <div class="block md:flex gap-[290px] justify-between">
      <h2 class="h2-heading uppercase mb-[60px] text-white">Contacts</h2>
      <div class="xl:w-1/2">
        <h5 class="h5-heading text-justify uppercase text-white mb-[54px] mt-0 md:mt-[10px]">Our team is always available to dive into your project and brainstorm ways we can join forces. Get in touch and let's ignite innovation together!</h5>
          <div>
            <div class="tags flex flex-wrap xl:gap-[16px] md:flex justify-between mb-[32px]">
              <div class="select_button"
                   :class="(this.selector.includes('App Development')) ? 'selected' : ''"
                   @click="toggleSelect('App Development')"
              >
                <span class="uppercase h7-heading whitespace-nowrap">
                  App Development
                </span>
              </div>

              <div class="select_button"
                   :class="(this.selector.includes('Monetization')) ? 'selected' : ''"
                   @click="toggleSelect('Monetization')"
              >
                <span class="uppercase h7-heading whitespace-nowrap">
                  Monetization
                </span>
              </div>

              <div class="select_button"
                   :class="(this.selector.includes('Advertising')) ? 'selected' : ''"
                   @click="toggleSelect('Advertising')"
              >
                <span class="uppercase h7-heading whitespace-nowrap">
                  Advertising
                </span>
              </div>
            </div>
            <div class="mb-[12px]">
              <label for="name" class="menu-font text-white">Name</label>
              <input type="text" v-model="name" id="name" class="form_input w-full mt-[12px]">
            </div>
            <div class="mb-[12px]">
              <label for="email" class="menu-font text-white">Email</label>
              <input type="email" v-model="email" id="email" class="form_input w-full mt-[12px]">
            </div>
            <div class="mb-[40px]">
              <label for="questions" class="menu-font text-white">Message</label>
              <textarea v-model="questions" id="questions" rows="3" class="form_input w-full mt-[12px]"></textarea>
            </div>
            <div @click="sendForm" class="send_button">
              <button class="flex items-center gap-[16px] ms-auto text-white" @submit.prevent>
                <span>SEND REQUEST</span>
                <svg width="82" height="32" viewBox="0 0 82 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 15C0.447715 15 0 15.4477 0 16C0 16.5523 0.447715 17 1 17V15ZM81.7071 16.7071C82.0976 16.3166 82.0976 15.6834 81.7071 15.2929L75.3431 8.92893C74.9526 8.53841 74.3195 8.53841 73.9289 8.92893C73.5384 9.31946 73.5384 9.95262 73.9289 10.3431L79.5858 16L73.9289 21.6569C73.5384 22.0474 73.5384 22.6805 73.9289 23.0711C74.3195 23.4616 74.9526 23.4616 75.3431 23.0711L81.7071 16.7071ZM1 17H81V15H1V17Z" fill="#1E1E1E"/>
                </svg>
              </button>
            </div>
          </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.form_input {
  border-radius: 8px;
  border: 1px solid #ffffff;
  background-color: #060606;
  font-size: 20px;
  padding: 10px;
  color: white;
}

.tags .select_button {
  background: #1E1E1E;
  padding: 8px 24px 8px 24px;
  border-radius: 8px;
  color: #ffffff;
  transition: ease-in-out .3s;
}

.tags .select_button.selected {
  background: linear-gradient(90deg, #FFC444, #ED6344);
  color: #ffffff;
}

.tags .select_button:hover {
  cursor: pointer;
  color: #060606;
  background-color: #ffffff;
}

form label {
  line-height: 21px;
}

label {
  line-height: 21px;
}

.send_button svg path {
  fill: #ffffff;
}

.send_button:hover button {
  color: #EB3756;
}

.send_button:hover svg path {
  fill: #EB3756;
}
@media (max-width: 610px) {
  .tags .select_button {
    padding: 4px 12px 4px 12px;
  }

  .select_button .h7-heading {
    font-size: 12px;
  }
}

@media (min-width: 769px) and (max-width: 1281px) {
  .tags .select_button {
    padding: 4px 12px 4px 12px;
  }

  .select_button .h7-heading {
    font-size: 12px;
  }
}

@media (min-width: 1440px) and (max-width: 1600px) {


  .select_button .h7-heading {
    font-size: 18px;
  }
}

@media (min-width: 1440px) {
  #contacts .menu-font {
    font-size: 24px;
  }

  #contacts .send_button {
    font-size: 24px;
  }
}
</style>