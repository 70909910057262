<script setup>
</script>

<template>
    <div id="home" class="relative">
      <video autoplay muted loop playsinline class="mobile-video sm:hidden justify-self-start items-start w-full h-full" src="/videos/hero_mobile.mp4" type="video/mp4"></video>
      <video autoplay muted loop playsinline class="small-video hidden xl:hidden sm:block sm:justify-self-start sm:items-start sm:w-full sm:h-full" id="backgroundVideo" src="/videos/hero_small_planshete.mp4" type="video/mp4"></video>
      <video autoplay muted loop playsinline class="small-video hidden xl:block xl:justify-self-start xl:items-start xl:w-full xl:h-full" id="backgroundVideo" src="/videos/hero_small.mp4" type="video/mp4"></video>
      <div class="absolute inset-0 flex justify-center items-center z-10 w-full">
        <div class="text-center sm:w-5/6">
          <h1 class="uppercase h1-heading text-white px-[16px] sm:px-0">
            Unlock the Power of Your
          </h1>
          <h1 class="uppercase h1-heading text-white px-[16px] sm:px-0">Content with Monview</h1>
        </div>
      </div>
    </div>
</template>

<style scoped>
.mobile-video {
  filter: brightness(0.4);
}

.small-video {
  filter: brightness(0.4);
}

@media (max-width: 1200px) {
  .h1-heading {
    font-size: 64px;
    font-family: "Big Shoulders Display", sans-serif;
    font-weight: 800;
    font-style: normal;
  }
}

@media (max-width: 768px) {
  .h1-heading {
    font-size: 36px;
    font-family: "Big Shoulders Display", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
}

@media (min-width: 768px) {
  .heroBlock {
    top: -50px;
  }
}

@media (min-width: 1199px) {
  .heroBlock {
    top: -70px;
  }
}

@media (min-width: 1380px) {
  .heroBlock {
    top: -112px;
  }
}

@media (min-width: 1440px) {
  .heroBlock {
    top: -130px;
  }
}

@media (min-width: 1600px) {
  .heroBlock {
    top: -160px;
  }
}
</style>