<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import {Navigation, A11y } from "swiper/modules";

import 'swiper/css';
import 'swiper/css/navigation';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation, A11y],
    }
  },
  data () {
    return {
      products: [
        {title: "Auto News", "btn_link": "#", image_path: "/images/products/Auto_News.png", roku_link: "https://channelstore.roku.com/details/69d09e773afb6df7961554270ecfdf33/auto-news", amazon_link: "https://www.amazon.com/gp/product/B0DGV5QPRR"},
        {title: "Trading Strategies", "btn_link": "#", image_path: "/images/products/Trading_Strategies.png", roku_link: "https://channelstore.roku.com/details/f3e036a8a7b55083d5420cee1fac9522/trading-strategies", amazon_link: "https://www.amazon.com/gp/product/B0DGVB1VXV"},
        {title: "Stunning Innovations", "btn_link": "#", image_path: "/images/products/Stunning_Innovations.png", roku_link: "https://channelstore.roku.com/details/8330856d488c08307d99b0fd1f948b00/stunning-innovations", amazon_link: "https://www.amazon.com/gp/product/B0DGVB8PKQ"},
        {title: "Cryptocurrency News", "btn_link": "#", image_path: "/images/products/Cryptocurrency_News.png", roku_link: "https://channelstore.roku.com/details/17a0cea6c00cac17467aa557704b5c73/cryptocurrency-news", amazon_link: "https://www.amazon.com/gp/product/B0DGV5STVX"},
        {title: "Smart Investing Strategies", "btn_link": "#", image_path: "/images/products/Smart_Investing_Strategies.png", roku_link: "https://channelstore.roku.com/details/edf0d17be1e63be7502ed279559d825b/smart-investing-strategies", amazon_link: "https://www.amazon.com/gp/product/B0DGV9R5XC"},
        {title: "Finance Mentor", "btn_link": "#", image_path: "/images/products/Finance_Mentor.png", roku_link: "https://channelstore.roku.com/details/ca0e0beffb384a7781d5ae3ea0e4bdbf/finance-mentor", amazon_link: "https://www.amazon.com/gp/product/B0DGV5Y77L"},
        {title: "The AI Zone", "btn_link": "#", image_path: "/images/products/The_AI_Zone.png", roku_link: "https://channelstore.roku.com/details/a90f83d5a2dbdfa0c3af3453e580b175/the-ai-zone", amazon_link: "https://www.amazon.com/gp/product/B0DGV52XG3"},
        {title: "Vote Vision", "btn_link": "#", image_path: "/images/products/Vote_Vision.png", roku_link: "https://channelstore.roku.com/details/7d84f56641eb0c958cf640605b498ee6/vote-vision", amazon_link: "https://www.amazon.com/gp/product/B0DGVVSF6S"},
      ],
      roku_link: '#',
      amazon_link: '#',
      showAppsLinksMenu: false,
    }
  },
  methods: {
    setRokuLink (payload) {
      this.roku_link = payload
    },
    setAmazonLink (payload) {
      this.amazon_link = payload
    },
    showApps (payload) {
      this.setRokuLink(payload.roku_link)
      this.setAmazonLink(payload.amazon_link)

      this.showAppsLinksMenu = true
    },
    hideApps () {
      this.showAppsLinksMenu = false
    }
  }
}
</script>

<template>
  <section id="products" class="max-w-[1110px] xl:max-w-[1730px] xl:px-10 mx-auto py-[60px] md:py-[80px] px-[16px] md:px-5">
    <h2 class="h2-heading uppercase pb-[20px] md:pb-[60px]">Our products</h2>

    <swiper
        :centered-slides="true"
        :navigation="{
              nextEl: '.swiper-next',
              prevEl: '.swiper-prev'
        }"
        :breakpoints="{
          300: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          745: {
            slidesPerView: 3,
            spaceBetween: 48
          }
        }"
        :modules="modules"
        :loop="true"
        class="swiperPaddings"
    >
      <swiper-slide v-slot="{ isActive }" v-for="(product, index) in products" :key="index">
        <div class="product-card" :class="isActive ? 'active' : ''">
          <img :src="product.image_path" :alt="product.title + ' image'" class="mx-auto product_image"/>
          <div class="flex flex-col justify-between max-h-[200px] min-h-[200px] 2xl:max-h-[200px] 2xl:min-h-[280px] h-full">
            <h3 class="h3-heading">{{ product.title }}</h3>

            <div class="product-button-font">
              <button @click="showApps(product)" class="go_to_app">GO TO APP</button>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>

    <div class="flex justify-center gap-[100px]">
      <div class="swiper-prev">
        <img src="@/assets/arrow_btn.svg" alt="slider arrow prev">
      </div>
      <div class="swiper-next">
        <img src="@/assets/arrow_btn.svg" alt="slider arrow next">
      </div>
    </div>

    <transition name="fade">
      <div v-if="showAppsLinksMenu" class="apps_popup">
          <div class="relative popup_wrap mx-auto">
            <img src="@/assets/close.svg" alt="close popup" @click="hideApps" class="absolute right-[32px] hover:cursor-pointer">
            <div class="button-group flex flex-col py-[160px] gap-[24px] mx-auto">
              <div>
                <a :href="roku_link" target="_blank" class="product-button-font uppercase text-white">
                  <button class="app_link text-white product-button-font uppercase">
                    Roku
                  </button>
                </a>
              </div>
              <div>
                <a :href="amazon_link" target="_blank" class="product-button-font uppercase text-white">
                  <button class="app_link text-white product-button-font uppercase">
                    Amazon
                  </button>
                </a>
              </div>
              <div>
                <button class="app_link disabled text-white product-button-font uppercase opacity-25">Lg</button>
              </div>
              <div>
                <button class="app_link disabled text-white product-button-font uppercase opacity-25">Hisense</button>
              </div>
            </div>
          </div>
      </div>
    </transition>
  </section>
</template>

<style scoped>
.swiper-next img {
  transform: scaleX(-1);
}

.product-card {
  padding: 0 24px 24px 24px;
  text-align: center;
  background-color: #fff;
  border-radius: 16px;
  transition: ease-in .2s;
}

.product-card.active {
  background: linear-gradient(90deg, #FFC444, #ED6344);
  scale: 1.1;
  -webkit-box-shadow: 0px 7px 15px 6px rgba(0,0,0,0.22);
  box-shadow: 0px 7px 15px 6px rgba(0,0,0,0.22);
}

@media (max-width: 745px) {
  .product-card.active {
    scale: 1;
  }
}

.go_to_app {
  padding: 12px 24px 12px 24px;
  border-radius: 8px;
  border: 1px solid #1e1e1e;
  width: 100%;
}

.product-card.active .go_to_app {
  background-color: #1e1e1e;
  color: #ffffff;
}

.product_image {
  position: relative;
  top: -50px;
  max-width: 140px;
  border-radius: 1em;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.apps_popup {
  position: fixed;
  left: 0;
  top: 150px;
  width: 100%;
  z-index: 1;
}

@media (max-width: 768px) {
  .apps_popup {
    top: 80px;
  }
}

.popup_wrap {
  max-width: 676px;
  width: 100%;
  background-color: #060606;
  background-image: url("@/assets/color_gradient_popup_bg.png");
  background-position: -200px 250px;
  background-repeat: no-repeat;
  border-radius: 18px;
  padding: 32px;
}

.app_link {
  width: 100%;
  padding: 12px 24px 12px 24px;
  border-radius: 8px;
  border: 1px solid #ffffff;
}

.button-group {
  max-width: 282px;
}

.app_link:hover {
  background: linear-gradient(90deg, #FFC444, #ED6344);
  border: 1px solid transparent;
}

.app_link.disabled:hover {
  background: #a1a1a1;
  border: 1px solid #ffffff;
}

.swiperPaddings {
  padding: 70px 16px 30px 16px;
}

@media (min-width: 768px) {
  .swiperPaddings {
    padding-bottom: 60px;
  }
}
</style>