<script>
export default {
  props: ['menuItem']
}
</script>

<template>
  <a :href="menuItem.link" class="menu-item menu-font uppercase">{{ menuItem.title }}</a>
</template>

<style scoped>
.menu-item {
  padding: 8px;
}

.menu-item:hover {
  color: #EB3756;
}
</style>